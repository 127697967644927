
import { ref } from "vue";
import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonLabel,
  IonSlide,
  IonSlides,
  IonCardContent,
  IonCardSubtitle,
  IonSpinner,
  IonItem,
  IonListHeader,
  IonList,
  IonChip,
  IonImg,
  IonFab,
  IonFabButton,
  modalController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  closeOutline,
  heart,
  languageOutline,
  alertCircleOutline,
  addOutline,
  arrowBackOutline,
  arrowForwardOutline,
} from "ionicons/icons";

import {
  loadMySuggestions,
  currentSuggestion,
  findingOnLoader,
  suggestionPhotos,
  noMoreSuggestions,
  errorOccured,
  loadingSuggestionProfile,
  sendLike,
  sendDislike,
} from "@/components/suggestions";

import SuggestionsSkeleton from "@/components/suggestions/SuggestionsSkeleton.vue";
import { darkModeEnabled } from "@/settings/settings";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "FinderTab",
  components: {
    SuggestionsSkeleton,
    IonPage,
    IonHeader,
    IonIcon,
    IonCardSubtitle,
    IonSpinner,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonSlide,
    IonFab,
    IonImg,
    IonFabButton,
    IonSlides,
    IonCardContent,
    IonLabel,
    IonItem,
    IonListHeader,
    IonList,
    IonChip,
  },
  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
    };

    loadMySuggestions();

    const showPhotosModal = async (index: number) => {
      const photosToShow: Array<any> = [];
      suggestionPhotos.value.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: index,
        },
      });
      return modal.present();
    };

    const photoSlides = ref<any>("");
    const nextSlide = async () => {
      photoSlides.value = document.getElementById("photosShow");
      await photoSlides.value.slideNext();
      await photoSlides.value.updateAutoHeight();
    };
    const prevSlide = async () => {
      photoSlides.value = document.getElementById("photosShow");
      await photoSlides?.value?.slidePrev();
      await photoSlides.value.updateAutoHeight();
    };

    return {
      nextSlide,
      prevSlide,
      showPhotosModal,
      darkModeEnabled,
      currentSuggestion,
      suggestionPhotos,
      loadingSuggestionProfile,
      findingOnLoader,
      noMoreSuggestions,
      errorOccured,
      sendDislike,
      sendLike,
      arrowBackOutline,
      arrowForwardOutline,
      slideOpts,
      informationCircleOutline,
      alertCircleOutline,
      briefcaseOutline,
      locationOutline,
      homeOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      trash,
      imagesOutline,
      close,
      languageOutline,
      addOutline,
      closeOutline,
      heart,
    };
  },
};
