
import {
  IonSkeletonText,
  IonCard,
  IonCardTitle,
  IonButton,
  IonCardHeader,
  IonLabel,
  IonListHeader,
  IonItem,
  IonList,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { darkModeEnabled } from "@/settings/settings";

export default defineComponent({
  components: {
    IonSkeletonText,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardHeader,
    IonLabel,
    IonListHeader,
    IonItem,
    IonList,
    IonCardContent,
  },
  setup() {
    return { darkModeEnabled };
  },
});
