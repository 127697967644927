<template>
  <ion-page>
    <ion-header>
      <ion-toolbar :collapse="true">
        <ion-title>{{ $t("tabs.suggestions") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content slot="fixed">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("tabs.suggestions") }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div v-if="findingOnLoader">
        <div style="text-align: center; margin-top: 15rem">
          <ion-spinner></ion-spinner>
          <p>{{ $t("suggestions.findingPartner") }}</p>
        </div>
      </div>
      <div v-else>
        <div v-if="noMoreSuggestions">
          <div style="text-align: center; margin: 2rem">
            <!-- <ion-icon
              :icon="alertCircleOutline"
              style="font-size: 64px"
            ></ion-icon> -->
            <ion-img style="width:200px; margin:auto;" src="/assets/empty_states/suggestion.png"></ion-img>
            <h6 style="margin-top: 6px">
              {{ $t("suggestions.noSuggestion") }}
            </h6>
          </div>
        </div>
        <div v-else>
          <div v-if="loadingSuggestionProfile">
            <SuggestionsSkeleton />
          </div>
          <div v-else-if="errorOccured || currentSuggestion == null">
            <div style="text-align: center; margin: 2rem">
              {{ $t("errors.problemOccured") }}
            </div>
          </div>
          <div v-else style="padding: 5px">
            <ion-card
              style="
                max-width: 500px;
                margin: 15px auto 25px;
                border-radius: 25px;
              "
            >
              <div style="height: 300px; margin: 5px; border-radius: 25px">
                <div
                  v-if="suggestionPhotos.length == 0"
                  style="
                    height: inherit;
                    border-radius: 25px;
                    text-align: center;
                  "
                  :style="{
                    backgroundColor: darkModeEnabled ? '#333' : '#eee',
                  }"
                >
                  <ion-icon
                    size="large"
                    :icon="personOutline"
                    style="height: 100%"
                  />
                </div>
                <div v-else style="height: inherit">
                  <div
                    style="position: absolute; height: inherit; width: 98.5%"
                  >
                    <ion-fab horizontal="start" vertical="center" slot="fixed">
                      <ion-fab-button
                        size="small"
                        @click="prevSlide()"
                        color="transparent"
                      >
                        <ion-icon :icon="arrowBackOutline"></ion-icon>
                      </ion-fab-button>
                    </ion-fab>
                    <ion-fab horizontal="end" vertical="center" slot="fixed">
                      <ion-fab-button
                        size="small"
                        @click="nextSlide()"
                        color="transparent"
                      >
                        <ion-icon :icon="arrowForwardOutline"></ion-icon>
                      </ion-fab-button>
                    </ion-fab>
                  </div>
                  <ion-slides
                    id="photosShow"
                    pager="true"
                    style="
                      height: inherit;
                      border-radius: 20px;
                      background-color: #333;
                    "
                    :options="slideOpts"
                  >
                    <ion-slide
                      v-for="(photo, index) in suggestionPhotos"
                      :key="index"
                    >
                      <div style="height: 100%; width: 100%">
                        <img
                          :src="photo"
                          style="
                            cursor: pointer;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                          "
                          @click="showPhotosModal(index)"
                        />
                      </div>
                    </ion-slide>
                  </ion-slides>
                </div>
              </div>
              <ion-card-header
                mode="ios"
                style="padding-bottom: 0px; text-align: center"
              >
                <ion-card-title>{{ currentSuggestion.name }}</ion-card-title>
                <ion-card-subtitle
                  ><p v-if="currentSuggestion.bio">
                    <q>{{ currentSuggestion.bio }}</q>
                  </p></ion-card-subtitle
                >
                <ion-button
                  @click="sendDislike(currentSuggestion.uid)"
                  shape="round"
                  color="light"
                  style="width: 120px; height: 3rem"
                  ><ion-icon slot="start" color="dark" :icon="closeOutline" />
                  <ion-label>{{ $t("buttons.no") }}</ion-label>
                </ion-button>
                <ion-button
                  @click="sendLike(currentSuggestion.uid)"
                  shape="round"
                  color="primary"
                  style="width: 120px; height: 3rem"
                >
                  <ion-icon slot="start" :icon="heart" />
                  <ion-label>{{ $t("buttons.yes") }}</ion-label>
                </ion-button>
              </ion-card-header>

              <ion-card-content style="padding: 10px">
                <ion-list lines="none">
                  <ion-list-header lines="full" mode="ios">
                    <ion-label class="sectionHeader">{{
                      $t("profile.mainInfo")
                    }}</ion-label>
                  </ion-list-header>
                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="briefcaseOutline"
                      style=""
                    ></ion-icon>
                    <ion-label position="fixed">
                      {{ $t("profile.work.work") }}
                    </ion-label>
                    <ion-chip>
                      <ion-label>{{
                        $t("profile.work.options." + currentSuggestion.work)
                      }}</ion-label>
                    </ion-chip>
                  </ion-item>

                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="schoolOutline"
                      style=""
                    ></ion-icon>
                    <ion-label position="fixed">{{
                      $t("profile.education.education")
                    }}</ion-label>
                    <ion-chip>
                      <ion-label>{{
                        $t(
                          "profile.education.options." +
                            currentSuggestion.education
                        )
                      }}</ion-label>
                    </ion-chip>
                  </ion-item>

                  <ion-item>
                    <ion-icon
                      slot="start"
                      :icon="locationOutline"
                      style=""
                    ></ion-icon>
                    <ion-label position="fixed">{{
                      $t("profile.location.location")
                    }}</ion-label>
                    <ion-chip>
                      <ion-label>{{
                        $t(
                          "profile.location.options." +
                            currentSuggestion.location
                        )
                      }}</ion-label>
                    </ion-chip>
                  </ion-item>
                </ion-list>

                <ion-list
                  v-if="
                    currentSuggestion.hobbies || currentSuggestion.customHobbies
                  "
                >
                  <ion-list-header lines="full" mode="ios">
                    <ion-label class="sectionHeader">{{
                      $t("profile.hobbies.hobbies")
                    }}</ion-label>
                  </ion-list-header>
                  <p>
                    <ion-chip
                      v-for="(item, index) in currentSuggestion.hobbies"
                      :key="index"
                    >
                      <ion-label>{{
                        $t("profile.hobbies.options." + item)
                      }}</ion-label>
                    </ion-chip>
                    <ion-chip
                      v-for="(item, index) in currentSuggestion.customHobbies"
                      :key="index"
                    >
                      <ion-label>{{ item }}</ion-label>
                    </ion-chip>
                  </p>
                </ion-list>

                <ion-list v-if="currentSuggestion.languages">
                  <ion-list-header lines="full" mode="ios">
                    <ion-label class="sectionHeader">{{
                      $t("profile.languages.languages")
                    }}</ion-label>
                  </ion-list-header>
                  <p>
                    <ion-chip
                      v-for="(item, index) in currentSuggestion.languages"
                      :key="index"
                    >
                      <ion-label>{{
                        $t("profile.languages.options." + item)
                      }}</ion-label>
                    </ion-chip>
                  </p>
                </ion-list>

                <ion-list
                  lines="none"
                  v-if="currentSuggestion.religion || currentSuggestion.smoking"
                >
                  <ion-list-header lines="full" mode="ios">
                    <ion-label class="sectionHeader"
                      >Ďalšie informácie</ion-label
                    >
                  </ion-list-header>
                  <ion-item v-if="currentSuggestion.smoking">
                    <ion-icon
                      slot="start"
                      :icon="briefcaseOutline"
                      style=""
                    ></ion-icon>
                    <ion-label position="fixed">
                      {{ $t("profile.smoking.smoking") }}</ion-label
                    >
                    <ion-chip>
                      <ion-label>{{
                        $t(
                          "profile.smoking.options." + currentSuggestion.smoking
                        )
                      }}</ion-label>
                    </ion-chip>
                  </ion-item>
                  <ion-item v-if="currentSuggestion.religion">
                    <ion-icon
                      slot="start"
                      :icon="schoolOutline"
                      style=""
                    ></ion-icon>
                    <ion-label position="fixed">{{
                      $t("profile.religion.religion")
                    }}</ion-label>
                    <ion-chip>
                      <ion-label>{{
                        $t(
                          "profile.religion.options." +
                            currentSuggestion.religion
                        )
                      }}</ion-label>
                    </ion-chip>
                  </ion-item>
                </ion-list>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { ref } from "vue";
import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonLabel,
  IonSlide,
  IonSlides,
  IonCardContent,
  IonCardSubtitle,
  IonSpinner,
  IonItem,
  IonListHeader,
  IonList,
  IonChip,
  IonImg,
  IonFab,
  IonFabButton,
  modalController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  closeOutline,
  heart,
  languageOutline,
  alertCircleOutline,
  addOutline,
  arrowBackOutline,
  arrowForwardOutline,
} from "ionicons/icons";

import {
  loadMySuggestions,
  currentSuggestion,
  findingOnLoader,
  suggestionPhotos,
  noMoreSuggestions,
  errorOccured,
  loadingSuggestionProfile,
  sendLike,
  sendDislike,
} from "@/components/suggestions";

import SuggestionsSkeleton from "@/components/suggestions/SuggestionsSkeleton.vue";
import { darkModeEnabled } from "@/settings/settings";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "FinderTab",
  components: {
    SuggestionsSkeleton,
    IonPage,
    IonHeader,
    IonIcon,
    IonCardSubtitle,
    IonSpinner,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonSlide,
    IonFab,
    IonImg,
    IonFabButton,
    IonSlides,
    IonCardContent,
    IonLabel,
    IonItem,
    IonListHeader,
    IonList,
    IonChip,
  },
  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
    };

    loadMySuggestions();

    const showPhotosModal = async (index: number) => {
      const photosToShow: Array<any> = [];
      suggestionPhotos.value.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: index,
        },
      });
      return modal.present();
    };

    const photoSlides = ref<any>("");
    const nextSlide = async () => {
      photoSlides.value = document.getElementById("photosShow");
      await photoSlides.value.slideNext();
      await photoSlides.value.updateAutoHeight();
    };
    const prevSlide = async () => {
      photoSlides.value = document.getElementById("photosShow");
      await photoSlides?.value?.slidePrev();
      await photoSlides.value.updateAutoHeight();
    };

    return {
      nextSlide,
      prevSlide,
      showPhotosModal,
      darkModeEnabled,
      currentSuggestion,
      suggestionPhotos,
      loadingSuggestionProfile,
      findingOnLoader,
      noMoreSuggestions,
      errorOccured,
      sendDislike,
      sendLike,
      arrowBackOutline,
      arrowForwardOutline,
      slideOpts,
      informationCircleOutline,
      alertCircleOutline,
      briefcaseOutline,
      locationOutline,
      homeOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      trash,
      imagesOutline,
      close,
      languageOutline,
      addOutline,
      closeOutline,
      heart,
    };
  },
};
</script>

<style scoped>
.heartButtonClass {
  /* box-shadow: 0px 5px 5px rgba(33, 33, 33, 0.4); */
  transform: translateY(-4px);
  font-weight: bold;
  --background: linear-gradient(
    162deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(145, 3, 34, 1) 58%,
    rgba(44, 0, 69, 1) 100%
  );
  --transition: all 0.3s ease;
}

button.notInterestedButton {
  outline: none;
  margin-right: 10px;
  background-color: lightgrey;
}

button.heartButton {
  outline: none;
  margin-left: 10px;
  background: linear-gradient(
    162deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(145, 3, 34, 1) 58%,
    rgba(44, 0, 69, 1) 100%
  );
  -webkit-box-shadow: 0px 0px 66px 1px rgb(99, 0, 41);
  -moz-box-shadow: 0px 0px 66px 1px rgb(99, 0, 41);
  box-shadow: 0px 0px 10px 2px rgb(99, 0, 41);
}

button.heartButton:active {
  transition: all 0.1s ease;
  box-shadow: none;
  transform: translateY(+2px);
}

ion-item {
  --background: inherit;
}

ion-list ion-chip {
  pointer-events: none;
  cursor: default;
}

ion-list {
  background: transparent;
}

ion-list p {
  padding: 0.5rem;
}
</style>