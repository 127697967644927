<template>
  <div style="max-width: 500px; margin: auto">
    <ion-card
      style="max-width: 500px; margin: 15px auto 25px; border-radius: 25px"
    >
      <div style="height: 300px; margin: 5px; border-radius: 25px">
        <div
          :style="{ backgroundColor: darkModeEnabled ? '#333' : '#eee' }"
          style="height: inherit; border-radius: 25px"
        ></div>
      </div>
      <ion-card-header
        mode="ios"
        style="padding-bottom: 0px; text-align: center"
      >
        <ion-card-title style="margin-bottom: 5px">
          <ion-skeleton-text
            animated
            style="width: 33%; line-height: 30px; margin: auto"
          ></ion-skeleton-text
        ></ion-card-title>
        <ion-button
          shape="round"
          disabled
          color="light"
          style="width: 120px; height: 3rem"
        >
        </ion-button>
        <ion-button
          disabled
          shape="round"
          color="light"
          style="width: 120px; height: 3rem"
        >
        </ion-button>
      </ion-card-header>

      <ion-card-content style="padding: 10px">
        <ion-list lines="none" style="border-radius: 20px">
          <ion-list-header lines="full">
            <ion-label
              ><ion-skeleton-text
                animated
                style="width: 30%; line-height: 18px"
              ></ion-skeleton-text
            ></ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script lang="ts">
import {
  IonSkeletonText,
  IonCard,
  IonCardTitle,
  IonButton,
  IonCardHeader,
  IonLabel,
  IonListHeader,
  IonItem,
  IonList,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { darkModeEnabled } from "@/settings/settings";

export default defineComponent({
  components: {
    IonSkeletonText,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardHeader,
    IonLabel,
    IonListHeader,
    IonItem,
    IonList,
    IonCardContent,
  },
  setup() {
    return { darkModeEnabled };
  },
});
</script>

<style scoped>
ion-skeleton-text {
  border-radius: 20px;
}

ion-avatar ion-skeleton-text {
  border-radius: 50%;
}
</style>
